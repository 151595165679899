import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImage  from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

function GedichtPage(props) {
  return (
    <>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading>Gedicht</Heading>
        </section>
        <Spacer/>
        <section>
          <div style={{ maxWidth: '420px', textAlign: 'justify', margin: 'auto'}}>
            <h3>Ich wünsche dir Zeit</h3>
            <p>Ich wünsche dir nicht alle möglichen Gaben. <br/>
              Ich wünsche dir nur, was die meisten nicht haben: <br/>
              Ich wünsche dir Zeit, dich zu freun und zu lachen, <br/>
              und wenn du sie nützt, kannst du etwas draus machen.</p>

            <p>Ich wünsche dir Zeit für dein Tun und dein Denken, <br/>
              nicht nur für dich selbst, sondern auch zum Verschenken. <br/>
              Ich wünsche dir Zeit – nicht zum Hasten und Rennen, <br/>
              sondern die Zeit zum Zufriedenseinkönnen.</p>

            <p>Ich wünsche dir Zeit – nicht nur so zum Vertreiben. <br/>
              Ich wünsche, sie möge dir übrig bleiben <br/>
              als Zeit für das Staunen und Zeit für Vertraun, <br/>
              anstatt nach der Zeit auf der Uhr nur zu schaun.</p>

            <p>Ich wünsche dir Zeit, nach den Sternen zu greifen, <br/>
              und Zeit, um zu wachsen, das heißt, um zu reifen. <br/>
              Ich wünsche dir Zeit, neu zu hoffen, zu lieben. <br/>
              Es hat keinen Sinn, diese Zeit zu verschieben.</p>

            <p>Ich wünsche dir Zeit, zu dir selber zu finden, <br/>
              jeden Tag, jede Stunde als Glück zu empfinden. <br/>
              Ich wünsche dir Zeit, auch um Schuld zu vergeben. <br/>
              Ich wünsche dir: Zeit zu haben zum Leben!</p>

            <p>Elli Michler <br/>
              Aus: Dir zugedacht, © Don Bosco Verlag, München 2004</p>
          </div>
        </section>
        <Spacer/>
        <section style={{ textAlign: 'center' }}>
          <CallToAction/>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default GedichtPage;

export const query = graphql`
  query GedichtPageQuery {
    headerImage: file(relativePath: {eq: "headers/gedicht.jpg"}) {
      name
      childImageSharp {
        fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;